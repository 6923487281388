<template>
  <div class="order_detail">
    <div class="basic_info">
      <div class="title">基本信息</div>
      <div class="info_box">
        <div class="item">订单编号：<span class="value">{{detail.orderNumber}}</span></div>
        <div class="item">下单时间：<span class="value">{{detail.createTime}}</span></div>
        <div class="item">服务名称：<span class="value">{{detail.goodsName}}</span></div>
        <div class="item">服务订单号：<span class="value" v-if="detail.serviceOrderList">{{detail.serviceOrderList[0].serverNumber}}</span></div>
        <div class="item">服务次数：<span class="value">{{detail.useNumber}}/{{detail.number}}</span></div>
        <div class="item">订单套餐：<span class="value">{{detail.specName}}{{detail.duration? detail.duration+'小时':''}}</span></div>
        <div class="item">支付时间：<span class="value">{{detail.paySuccessTime}}</span></div>
        <div class="item">服务状态：<span class="value">{{status[serviceOrder.status]}}</span></div>
        <div class="item" v-if="detail.remark">订单取消原因：<span class="value">{{detail.remark||''}}</span></div>
      </div>
    </div>

    <div class="user_info">
      <div class="title">用户信息</div>
      <div class="info_box">
        <div class="item">用户姓名：<span class="value">{{serviceOrder.userName}}</span></div>
        <div class="item">手机号码：<span class="value">{{serviceOrder.telNumber}}</span></div>
        <div class="item">用户来源：<span class="value">{{'未知'}}</span></div>
        <div class="item">服务地址：<span class="value">{{serviceOrder.provinceName}}{{serviceOrder.cityName}}{{serviceOrder.countyName}}{{serviceOrder.detailInfo}}</span></div>
      </div>
    </div>

    <div class="staff_info" v-if="hmServiceStaffVo">
      <div class="title">服务人员信息</div>
      <div class="info_box">
        <div class="item">服务人员姓名：<span class="value">{{hmServiceStaffVo.name}}</span></div>
        <div class="item">手机号码：<span class="value">{{hmServiceStaffVo.phone}}</span></div>
        <div class="item">地址：<span class="value">
          {{hmServiceStaffVo.province}}{{hmServiceStaffVo.city}}{{hmServiceStaffVo.county}}{{hmServiceStaffVo.address}}
        </span></div>
      </div>
    </div>

    <div class="serve_info">
      <div class="title">商品信息</div>
      <ch-table :render-option="serveInfoOption" :data="serveInfo" class="mt-10"
                :border="true" :header-cell-style="{background:'#FAFAFA'}"
                :props="{width: '100%',  size: 'mini'}">
        <template v-slot:amount="scope">
          <div>{{scope.row.amount}} <ch-icon v-if="scope.row.payType===3" style="font-size: 20px;margin-right: 5px" name="payTypeBefore" /></div>
        </template>
      </ch-table>
    </div>

    <div class="value_info" v-if="appreciationOrdersList.length>0">
      <div class="title">增值服务</div>
      <div class="table_head">
        <div>增值服务明细</div>
        <div>实付金额(/元)</div>
        <div>操作</div>
      </div>
      <div v-for="(item,index) in appreciationOrdersList" :key="index">
        <div class="table_body" v-for="(child,childIndex) in item.appreciationOrderDetailList" :key="childIndex" >
          <div>{{child.name}}</div>
          <div>{{child.amount}}</div>
          <div></div>
        </div>
        <div class="table_and">
          <div>合计</div>
          <div>{{item.amount}}</div>
          <div class="refund">退款</div>
        </div>
      </div>
    </div>

    <div class="flowList">
      <div class="title" style="padding-bottom: 10px;">服务进度</div>
      <ch-table :render-option="flowOption" :data="flowList" class="mt-10"
                :border="true" :header-cell-style="{background:'#FAFAFA'}"
                :props="{width: '100%',  size: 'mini'}">
        <template v-slot:type="scope">
          <div>{{serveType[scope.row.type]||'一'}}</div>
        </template>
        <template v-slot:causeImg="scope">
          <div v-if="(scope.row.type===4||scope.row.type===5||scope.row.type===6)&&scope.row.cause" style="color: #3d7fff;cursor: pointer" @click="openImgView(scope.row.cause)">查看</div>
        </template>
      </ch-table>
    </div>

    <div class="splitMoneyInfo">
      <div class="title" style="padding-bottom: 10px;">本单结算</div>
      <ch-table :render-option="splitMoneyInfoOption" :data="splitMoneyInfoVoList" class="mt-10"
                :border="true" :header-cell-style="{background:'#FAFAFA'}"
                :props="{width: '100%',  size: 'mini'}">
        <template v-slot:type="scope">
          <div>{{serveType[scope.row.type]||'一'}}</div>
        </template>
        <template v-slot:causeImg="scope">
          <div v-if="(scope.row.type===4||scope.row.type===5||scope.row.type===6)&&scope.row.cause" style="color: #3d7fff;cursor: pointer" @click="openImgView(scope.row.cause)">查看</div>
        </template>
      </ch-table>
    </div>

    <el-image-viewer
        v-if="showViewer"
        :z-index="9999"
        :on-close="closeViewer"
        :url-list="viewerList" />
  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  components:{ElImageViewer},
  data() {
    return {
      detail:{},
      serveInfo:[],
      serviceOrder:{},
      hmServiceStaffVo:{},
      appreciationOrdersList:[],
      status:['商品订单待支付','待服务','已取消','已评价','已完成','服务中','待验收','待接单','待签到','申诉中'],
      serveType: ['一','预约','取消','修改','开始','待验收','已完成','已接单','更换服务人员', '申请改期', '同意改期','拒绝改期','签到'], //cause 5 7
      flowList:[],
      splitMoneyInfoVoList:[],
      showViewer:false,
      viewerList:[],
    }
  },
  created() {
    if(this.$route.query.orderId){
      this.getOrderDetail(this.$route.query.orderId)
    }
  },
  computed: {
    serveInfoOption() {
      return [
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: "text", label: "服务名称", prop: "goodsName",},
        {column: "text", label: "服务明细", prop: "serveDetail", showOverflowTooltip: true,},
        {column: "text", label: "服务时长", prop: "duration",},
        {column: "cash", label: "服务金额", prop: "price",},
        {column: "cash", label: "增值金额", prop: "appreciation"},
        {column: "slot", label: "合计金额", slotName: "amount",},
      ]
    },
    tableOption() {
      return [
        {column: "text", label: "交易时间", prop: "createTime",},
        {column: "text", label: "订单号", prop: "orderNumber",},
        {column: "text", label: "服务订单号", prop: "serverNumber"},
        {column: "text", label: "合计金额", prop: "price",},
        {column: "slot", label: "状态", slotName: "status",},
      ];
    },
    flowOption() {
      return [
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        // {column: "text", label: "服务人员", prop: "createTime",},
        {column: "text", label: "当前进度", prop: "detail",},
        {column: "slot", label: "订单状态", slotName: "type"},
        {column: "text", label: "操作时间", prop: "createTime",},
        // {column: "text", label: "用户评价", slotName: "status",},
        {column: "slot", label: "服务图片", slotName: "causeImg",},
      ];
    },
    splitMoneyInfoOption() {
      return [
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: "text", label: "类型", prop: "_type",},
        {column: "cash", label: "订单金额", prop: "amount",},
        {column: "cash", label: "扣罚", prop: "refundAmount"},
        {column: "text", label: "平台服务费比例", prop: "proportion",},
        {column: "cash", label: "平台服务费", prop: "chAmount",},
        {column: "text", label: "服务员佣金比例", prop: "commissionRate",},
        {column: "cash", label: "服务员佣金", prop: "staffAmount",},
        {column: "cash", label: "预计收入", prop: "hmAmount",},
      ];
    },
  },
  methods: {
    getOrderDetail(orderId) {
      this.$curl.get('/hm/order/serviceDetail/'+orderId).then(res => {
        this.detail = res.data
        this.flowList = this.detail.serviceOrderList[0].flowList
        this.splitMoneyInfoVoList = this.detail.serviceOrderList[0].splitMoneyInfoVoList.map(item=>{
          return {
            ...item,
            proportion:item.proportion + '%',
            commissionRate:item.commissionRate + '%',
            _type:item.type===1?'服务订单':item.type===2?'增值订单':'一'
          }
        })
        const {price,amount,appreciation} = this.detail?.serviceOrderList[0]
        this.serveInfo = [
          {
            goodsName:this.detail.goodsName,
            price,amount,appreciation,
            duration: this.detail.duration? this.detail.duration+'小时':'一',
            serveDetail: JSON.parse(this.detail.serveDetail).join(),
            payType:this.detail.payType
          }
        ]
        this.serviceOrder = this.detail.serviceOrderList[0]
        this.hmServiceStaffVo = this.serviceOrder.hmServiceStaffVo
        this.appreciationOrdersList = this.detail.appreciationOrdersList
      })
    },
    openImgView(url){
      this.viewerList = url.split(',')
      this.showViewer = true
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },

  }
}
</script>
<style scoped lang="scss">
.order_detail{
  background: #FFFFFF;
  height: 100%;
  width: 100%;
  margin-top: 15px;
  overflow: auto;
  padding-bottom: 40px;
}

.basic_info,
.serve_info,
.user_info,
.staff_info,
.value_info,
.flowList,
.splitMoneyInfo{
  margin: 0 42px;
  padding: 16px 0;
  .title{
    font-weight: bold;
    padding-bottom: 2px;
  }

}
.basic_info,
.user_info,
.staff_info{
  border-bottom: 1px solid #E8E8E8;
  .info_box{
    display: flex;
    flex-wrap: wrap;
    .item{
      width: 33.33%;
      padding-top: 10px;
      font-size: 14px;
      // 换行
      white-space: pre-wrap;
      .value{
        color: #666666;
      }
    }
  }
}

.value_info{
  .table_head{
    font-size: 14px;
    display: flex;
    font-weight: bold;
    line-height: 35px;
    background: #FAFAFA;
    border-radius: 5px 5px 0 0;
    justify-content: space-between;
    padding: 0 32px;
    margin-top: 12px;
    >div{
      width: 33.33%;
    }
    :nth-child(2){
      text-align: center;
    }
    :last-child{
      text-align: right;
    }
  }
  .table_body,
  .table_and{
    color: #666666;
    font-size: 12px;
    display: flex;
    line-height: 35px;
    justify-content: space-between;
    padding: 0 32px 0 32px;
    border: 1px solid #E8E8E8;
    border-top: none;
    >div{
      width: 33.33%;
    }
    :nth-child(2){
      text-align: center;
    }
    :last-child{
      text-align: right;
    }
    .refund{
      color: #3D7FFF;
      cursor: pointer;
    }
  }
  .table_and{
    margin-bottom: 10px;
  }
  .table_body:first-child{
    border-top: 1px solid #E8E8E8;
  }
}
.flowList{
  .flowList_warp{
    padding-bottom: 10px;
    .createTime{
      color: #999999;
      padding-top: 10px;
    }
  }
}

::v-deep(.el-table td.el-table__cell){
  border-bottom: 1px solid #ebeef5;
}
</style>